if (!Modernizr.objectfit) {
    $(".object-fit-wrapper").each(function () {
        var $container = $(this),
            imgUrl = $container.find("img").prop("src");
        if (imgUrl) {
            $container
                .css("backgroundImage", "url(" + imgUrl + ")")
                .addClass("object-fit-fallback");
        }
    });
}
