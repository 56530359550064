function search() {
    
    const body = document.querySelector(".js-body"),
        searchButton = document.querySelectorAll(".js-search-button"),
        header = document.querySelector(".js-header"),
        searchOverlay = document.querySelector(".js-s-overlay"),
        stopScrollingClass = "stop-scrolling"
    ;

    let isActive = false;

    //open search timeline
    const searchOpenTl = gsap.timeline({paused:true});

    searchOpenTl.call(addSearchIsActiveClass, [header], "+=0")
        .to(searchOverlay, 0.3, { ease: Power4.easeInOut, autoAlpha: 1 } )


    //close menu timeline
    const searchCloseTl = gsap.timeline({paused:true});

    searchCloseTl.to(searchOverlay, 0.3, { ease: Power4.easeInOut, autoAlpha: 0})
        .call(removeSearchIsActiveClass, [header], "-=0.3");


    [].forEach.call(searchButton, function(btn) {
        btn.addEventListener("click", (e) => {
            e.preventDefault();

            isActive = !isActive;

            if (isActive) {
                body.classList.add(stopScrollingClass);
                openSearchAnimations(searchOpenTl,searchCloseTl,btn, header);
            } else {
                closeSearchAnimations(searchOpenTl,searchCloseTl, btn,header);
                body.classList.remove(stopScrollingClass);
            }
        });
    });


    
}

 //functions
function openSearchAnimations(searchOpenTl,searchCloseTl) {
    searchCloseTl.pause();
    searchOpenTl.restart();
}

function closeSearchAnimations(searchOpenTl,searchCloseTl) {
    searchOpenTl.pause();
    searchCloseTl.restart();
}

function addSearchIsActiveClass(header) {
    header.classList.add("search-is-active");
}

function removeSearchIsActiveClass(header) {
    header.classList.remove("search-is-active");
}

