function podcast() {
    const body = document.querySelector(".js-body"),
        podcastCard = document.querySelectorAll(".js-podcast-card"),
        podcastOverlay = document.querySelector(".js-podcast-overlay"),
        closePodcastOverlayButton = document.querySelectorAll(
            ".js-close-podcast-overlay"
        ),
        listenSpotify = document.querySelector(".js-listen-spotify"),
        listenItunes = document.querySelector(".js-listen-itunes"),
        listenYoutube = document.querySelector(".js-listen-youtube"),
        embedIframe = document.querySelector(".js-overlay-iframe");

    podcastCard.forEach((btn) => {
        btn.addEventListener("click", (e) => {
            e.preventDefault();

            // add classes and animate overlay
            body.classList.add("stop-scrolling");
            podcastOverlay.classList.add("is-active");
            gsap.to(podcastOverlay, 0.4, {
                ease: Power4.easeInOut,
                autoAlpha: 1
            });

            //reset button classes
            listenSpotify.classList.remove("is-hidden");
            listenItunes.classList.remove("is-hidden");
            listenYoutube.classList.remove("is-hidden");

            //get data attr from card
            const spotifyLink = btn.dataset.spotifylink;
            const itunesLink = btn.dataset.ituneslink;
            const youtubeLink = btn.dataset.youtubelink;
            const podcastSrc = btn.dataset.embedsrc;


            //add embed src to iframe 
            //if no data, hide the iframe
            if (podcastSrc) {
                embedIframe.classList.remove("is-hidden");
                embedIframe.src = podcastSrc;
            } else {
                embedIframe.classList.add("is-hidden");
            }

            //populate buttonlinks with data
            //if no data, hide the button
            if (spotifyLink) {
                listenSpotify.href = spotifyLink;
            } else {
                listenSpotify.classList.add("is-hidden");
            }

            //populate buttonlinks with data
            //if no data, hide the button
            if (itunesLink) {
                listenItunes.href = itunesLink;
            } else {
                listenItunes.classList.add("is-hidden");
            }

            //populate buttonlinks with data
            //if no data, hide the button
            if (youtubeLink) {
                // listenYoutube.href = "";
                listenYoutube.href = youtubeLink;
            } else {
                listenYoutube.classList.add("is-hidden");
            }

        });
    });

    closePodcastOverlayButton.forEach((btn) => {
        btn.addEventListener("click", (e) => {
            e.preventDefault();

            // remove classes and animate overlay
            podcastOverlay.classList.remove("is-active");
            gsap.to(podcastOverlay, 0.4, {
                ease: Power4.easeInOut,
                autoAlpha: 0
            });
            body.classList.remove("stop-scrolling");

            //reset embed src
            if (embedIframe) {
                embedIframe.src = '';
            }

            //reset button links
            if (listenSpotify) {
                listenSpotify.href = "";
            }

            if (listenItunes) {
                listenItunes.href = "";
            }

            if (listenYoutube) {
                listenYoutube.href = "";
            }

        });
    });
}