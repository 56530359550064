(function($){
	
	$.fn.rhLoadIn=function(options){
		
		var settings = $.extend({ 
			scrollThreshold:50,
			ajax:false
		},options);
				
		var rhLoadInElements=this.find('.load-in'),
			rhLoadInSize=rhLoadInElements.length;

		if (settings.ajax === false) {
			
			var loadInMatrix=[];
			
			loadInLocations();
			$(window).on('scroll',loadInLoop);	
			
			$(window).resize(function() {
				loadInLocations();
			});
		
		} else {
			
			var loadInMatrixAjax=[];
			var $ajaxWrapper = $(this);
			
			loadInLocationsAjax();
			
			$ajaxWrapper.on('scroll',loadInLoopAjax);	
			
			$(window).resize(function() {
				loadInLocationsAjax();
			});

		}
		
		
		function loadInLocations(){
			
			for( var i=0;i<rhLoadInSize;i++){
				loadInMatrix[i]=loadInMatrix[i]||{el:rhLoadInElements[i]}
				var rect=loadInMatrix[i].el.getBoundingClientRect();
				loadInMatrix[i].start=rect.top+window.pageYOffset+settings.scrollThreshold;
			}
			
			loadInLoop();
			
		}
			
		function loadInLoop(){

			var viewportBottom = $(window).scrollTop() + window.innerHeight; 
			
			for(var i=0;i<rhLoadInSize;i++){
				
				if(loadInMatrix[i].start<viewportBottom){
					var thisElement = $(loadInMatrix[i].el);
					thisElement.addClass('is-visible').delay(2000).queue(function(next){
						thisElement.removeClass('load-in');
						next();
					});
				}
				
			}
		
		}		
		
		function loadInLocationsAjax(){
			
			for( var i=0;i<rhLoadInSize;i++){
				
				loadInMatrixAjax[i] = loadInMatrixAjax[i] || { el: rhLoadInElements[i] }	        
				var newoffset = $(loadInMatrixAjax[i].el).offset().top - window.pageYOffset;			 
				loadInMatrixAjax[i].start = newoffset + settings.scrollThreshold;
			}
			loadInLoopAjax();
			
		}
			
		function loadInLoopAjax(){ 
			
			var viewportBottom = $ajaxWrapper.scrollTop() + window.innerHeight; 
			
			for(var i=0;i<rhLoadInSize;i++){
				
				if(loadInMatrixAjax[i].start<viewportBottom){
					var thisElement = $(loadInMatrixAjax[i].el);
					thisElement.addClass('is-visible').delay(2000).queue(function(next){
						thisElement.removeClass('load-n');
						next();
					});
				}
				
			}
		
		}
			

		
	}

}(jQuery));	



