//var mql = window.matchMedia('(max-width: 600px)');

function menu(e) {
    
    const body = document.querySelector(".js-body"),
        menuToggle = document.querySelectorAll(".js-menu-button"),
        header = document.querySelector(".js-header"),
        // mobileMenu = document.querySelector(".js-menu"),
        staggerItems = document.querySelectorAll(".js-animate-stagger"),
        menuImage = document.querySelector(".js-menu-image"),
        // menuBg = document.querySelector(".js-menu-bg"),
        menuSwoosh = document.querySelectorAll(".js-menu-swoosh"),
        // menuSwoosh = document.querySelectorAll(".js-menu-swoosh-dt"),
        // menuSwooshMob = document.querySelectorAll(".js-menu-swoosh-mob"),
        stopScrollingClass = "stop-scrolling"
    ;

    let isActive = false;

    //open menu timeline
    const menuOpenTl = gsap.timeline({paused:true});

    menuOpenTl.call(addIsActiveClass, [header], "+=0")
        .to(mobileMenu, 0.6, { ease: Power4.easeInOut, xPercent: 0 } )
        .to(staggerItems, { x: 0, autoAlpha: 1, stagger: 0.05, ease: Power4.easeInOut }, "-=0.4")
        .to(menuImage, 0.9, { x: 0, autoAlpha: 1, ease: Power4.easeInOut}, "-=1.2" );


    //close menu timeline
    const menuCloseTl = gsap.timeline({paused:true, onComplete: menuSwooshReset});

    menuCloseTl.to(staggerItems, { x: "3rem", autoAlpha: 0, stagger: 0.05, ease: Power4.easeInOut })
        .to(menuImage, 0.9, { x: "100%", ease: Power4.easeInOut}, "-=0.8" )
        .to(mobileMenu, 0.4, { ease: Power4.easeInOut, xPercent: 100 }, "-=0.6" )
        .call(removeIsActiveClass, [header], "-=0.6");

    menuCloseTl.eventCallback("onComplete", function() {
        gsap.set(menuImage, { autoAlpha: 0 });
    });


    //swoosh timeline
    const menuSwooshTl = gsap.timeline({paused:true});
    menuSwooshTl.to(menuSwoosh, 2000, {rotation:"-=360", scale: "+=2"});

    //swoosh timeline desktop
    // const menuSwooshDtTl = gsap.timeline({paused:true});
    // menuSwooshDtTl.to(menuSwooshDt, 2000, {rotation:"-=360"});menuSwoosh

    //swoosh timeline mobile
    // const menuSwooshMobTl = gsap.timeline({paused:true});
    // menuSwooshMobTl.to(menuSwooshMob, 2000, {rotation:"-=360"});       



    //set the X value of the flyout
    gsap.set(mobileMenu, { xPercent: 100, opacity: 1 });


    [].forEach.call(menuToggle, function(btn) {
        btn.addEventListener("click", (e) => {
            e.preventDefault();

            isActive = !isActive;

            if (isActive) {
                body.classList.add(stopScrollingClass);
                openMenuAnimations(menuOpenTl,menuCloseTl,btn, header);
                menuSwooshStart(menuSwooshTl,btn );
            } else {
                closeMenuAnimations(menuOpenTl,menuCloseTl, btn,header);
                menuSwooshStop(menuSwooshTl,btn);
                body.classList.remove(stopScrollingClass);
            }
        });
    });

}

 //functions
function openMenuAnimations(menuOpenTl,menuCloseTl) {
    menuCloseTl.pause();
    menuOpenTl.restart();
}

function closeMenuAnimations(menuOpenTl,menuCloseTl) {
    menuOpenTl.pause();
    menuCloseTl.restart();
}

function addIsActiveClass(header) {
    header.classList.add("is-active");
}

function removeIsActiveClass(header) {
    header.classList.remove("is-active");
}


function menuSwooshStart(menuSwooshTl) {
    menuSwooshTl.pause();
    menuSwooshTl.restart();
}

function menuSwooshStop(menuSwooshTl) {
    menuSwooshTl.pause();
}

function menuSwooshReset(menuSwooshTl) {
    menuSwooshTl.restart();
}



// var mql = window.matchMedia('(max-width: 600px)');
//   function menu(e) {
//     if (e.matches) {
//       /* the viewport is 600 pixels wide or less */
//       console.log('the viewport is 600 pixels wide or less');

//     } else {
//       /* the viewport is more than than 600 pixels wide */
//       console.log('the viewport is more than than 600 pixels wide');

//     }
//   }


//   menu(mql)
//   mql.addListener(menu);