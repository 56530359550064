//Codekit skank -->

// @codekit-prepend "global/vars.js"

// @codekit-prepend "utilities/rhparallax.js"
// @codekit-prepend "utilities/loadIn.js"
// @codekit-prepend "utilities/debounce.js"
// @codekit-prepend "utilities/object-fit.js"

// @codekit-prepend "components/cookies.js"
// @codekit-prepend "components/menu.js"
// @codekit-prepend "components/menuSize.js"
// @codekit-prepend "components/inlineVideo.js"
// @codekit-prepend "components/imageCarousel.js"
// @codekit-prepend "components/quoteCarousel.js"
// @codekit-prepend "components/videoReadMore.js"
// @codekit-prepend "components/articleReadMore.js"
// @codekit-prepend "components/heroDiscoverMore.js"
// @codekit-prepend "components/header.js"
// @codekit-prepend "components/hero.js"
// @codekit-prepend "components/share.js"
// @codekit-prepend "components/podcast.js"
// @codekit-prepend "components/podcast.js"
// @codekit-prepend "components/search.js"

// @codekit-prepend "components/videoFilter.js"


// @codekit-append "global/resize.js"

//fire funcitons
//header
window.addEventListener("scroll", debounce(headerReveal));

//menu
menu();
menuSize();

//search
search();

//hero discover more
if (typeof hero != "undefined" && hero != null) {
    heroSize();
}

//hero discover more
if (
    typeof heroDiscoverMoreButton != "undefined" &&
    heroDiscoverMoreButton != null
) {
    heroDiscoverMore();
}

//video read more
if (typeof videoReadMoreButton != "undefined" && videoReadMoreButton != null) {
    videoReadMore();
}

//article read more
if (
    typeof articleReadMoreButton != "undefined" &&
    articleReadMoreButton != null
) {
    articleReadMore();
}

//podcasts
if (typeof podcastCard != "undefined" && podcastCard != null) {
    podcast();
}

//video filter
// if(typeof(videoFilterButton) != 'undefined' && videoFilterButton != null){
//     videoFilter();
// }

//share
if (typeof shareButton != "undefined" && shareButton != null) {
    share();
    socialShare();
}

if (typeof carousels != "undefined" && carousels != null) {
    imageCarouselInit();
}

if (typeof quoteCarousels != "undefined" && quoteCarousels != null) {
    quoteCarouselInit();
}

//on ready
$(document).ready(function () {
    $body.rhLoadIn();

    $(".parallax").rhParallax({
        parallaxRatio: 1.2,
    });

    //images loaded
    $(".bg-img-load").imagesLoaded({ background: ".bgImgLoad" }, function () {
        $(".bg-img-load").animate({ opacity: "1" }, 300);
    });

    $(".img-load").imagesLoaded(function () {
        $(".img-load").animate({ opacity: "1" }, 300);
    });

    if (typeof hero != "undefined" && hero != null) {

        $(".hero__image").imagesLoaded(function () {
            $(".hero__image").animate({ opacity: "0.7" }, 300);
        });

        const txtLoadin = gsap.timeline({ delay: 0.5 });

        txtLoadin
        .to(".hero__title", 0.3, {
            ease: Power2.easeInOut,
            autoAlpha: 1,
            marginTop: 0,
        })
        .to(".hero__sub-title", 0.3, {
            ease: Power2.easeInOut,
            autoAlpha: 1,
            marginTop: 0,
        });
    }

}); // end doc ready