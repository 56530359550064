function share() {
    const body = document.querySelector(".js-body"),
        shareButton = document.querySelectorAll(".js-share-button"),
        shareOverlay = document.querySelector(".js-share-overlay"),
        closeShareOverlayButton = document.querySelectorAll(
            ".js-close-share-overlay"
        ),
        copyButton = document.querySelectorAll(".js-copy-button"),
        responseMsg = document.querySelector(".js-share-response");

    // let overlayIsOpen = false;

    shareButton.forEach((btn) => {

        btn.addEventListener("click", (e) => {
            e.preventDefault();

            // overlayIsOpen = true;

            body.classList.add("stop-scrolling");
            shareOverlay.classList.add("is-active");
            gsap.to(shareOverlay, 0.4, {
                ease: Power4.easeInOut,
                autoAlpha: 1
            });
        });
    });

    closeShareOverlayButton.forEach((btn) => {

        btn.addEventListener("click", (e) => {
            e.preventDefault();

            //overlayIsOpen = false;

            shareOverlay.classList.remove("is-active");
            gsap.to(shareOverlay, 0.4, {
                ease: Power4.easeInOut,
                autoAlpha: 0
            });
            body.classList.remove("stop-scrolling");
        });
    });

    //clipboard
    let clipboard = new ClipboardJS(copyButton);

    clipboard.on("success", (e) => {
        // console.info("Action:", e.action);
        // console.info("Text:", e.text);
        // console.info("Trigger:", e.trigger);

        console.log("Successfully copied link" + " " + e.text);

        responseMsg.style.opacity = 1;
        responseMsg.innerHTML = "Successfully copied link";

        e.clearSelection();
    });

    clipboard.on("error", (e) => {
        // console.error("Action:", e.action);
        // console.error("Trigger:", e.trigger);

        console.log("Failed to copy link" + " " + e.action);

        responseMsg.style.opacity = 1;
        responseMsg.innerHTML = "Failed to copy link. Please try again.";
    });
}

function socialShare(network, url, title) {
    var winWidth = 520,
        winHeight = 350,
        winTop = screen.height / 2 - winHeight / 2,
        winLeft = screen.width / 2 - winWidth / 2,
        popupMeta = "top=" + winTop + ",left=" + winLeft + ",toolbar=0,status=0,width=" + winWidth + ",height=" + winHeight;

    if (network == "facebook") {
        window.open("http://www.facebook.com/sharer/sharer.php?u=" + url,"sharer", popupMeta);
    } else if (network == "twitter") {
        window.open("https://twitter.com/intent/tweet?text=" + title + "%20-%20&url=" + url, "sharer", popupMeta);
    }
}
