function quoteCarouselInit() {

    quoteCarousels.forEach((carousel) => {

        //set up new flickity for each carousel block
        var flkty = new Flickity(carousel, {
            // options
            cellAlign: "center",
            contain: true,
            accessibility: true,
            prevNextButtons: false,
            imagesLoaded: true,
            lazyLoad: 3,
            pageDots: false,
            setGallerySize: true,
            selectedAttraction: 0.2,
            friction: 0.9,
            resize: true,
            wrapAround: true
            // adaptiveHeight: true
        });

        const carouselParent = carousel.parentNode,
        prevButton = carouselParent.querySelector('.js-quote-prev'),
        nextButton = carouselParent.querySelector('.js-quote-next');

        //click through slides
        prevButton.addEventListener( 'click', function() {
            flkty.previous();
        });

        nextButton.addEventListener( 'click', function() {
            flkty.next();
        });
    });
}
