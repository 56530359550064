function headerReveal() {

    let windowY = window.scrollY;

    if (windowY > scrollPos) {
        // Scrolling DOWN
        //console.log("scroll-down");

        if (windowY > headerTriggerPoint) {
            header.classList.add("is-hidden");
            header.classList.remove("is-visible");
        }

    } else {
        // Scrolling UP
        //console.log("scroll-up");

        header.classList.add("is-visible");
        header.classList.remove("is-hidden");

        if (windowY > headerTriggerPoint) {
            header.classList.add("fill-bg");
        } else if (windowY <= 0) {
            header.classList.remove("fill-bg");
        }

    }

    scrollPos = windowY;

}