var $body = $("body"),
    // site = $body.attr('data-site'),
    thisPage = $body.attr("data-page"),
    height = $(window).height(),
    width = $(window).width();

const body = document.querySelector('.js-body'), 
    header = document.querySelector(".js-header"),
    heroDiscoverMoreButton = document.querySelector(".js-discover-more-button"),
    videoReadMoreButton = document.querySelector(".js-read-more-video"),
    articleReadMoreButton = document.querySelector(".js-read-more-artist"),
    podcastCard = document.querySelectorAll(".js-podcast-card"),
    shareButton = document.querySelectorAll(".js-share-button"),
    // videoFilterButton = document.querySelector('.js-video-filter-button'),
    hero = document.querySelector('.js-hero'),
    mobileMenu = document.querySelector('.js-menu'),
    heroTitle = document.querySelector('.js-hero-title'),
    heroSubTitle = document.querySelector('.js-hero-sub-title'),
    carousels = document.querySelectorAll(".js-carousel"),
    quoteCarousels = document.querySelectorAll(".js-quote-carousel"),
    searchButton = document.querySelector('.js-search-button');

let scrollPos = 0,
    headerTriggerPoint = header.clientHeight;
