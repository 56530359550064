function videoReadMore() {
    const videoReadMoreButton = document.querySelector(".js-read-more-video"),
        videoRevealPanel = document.querySelector(".js-read-more-reveal-panel"),
        videoRevealContent = document.querySelector(".js-read-more-panel-content"),
        videoRevealContentHeight = videoRevealContent.clientHeight + 'px';

    let isActive = false;

    //timelines
    //open reveal tl
    const contentRevealOpenTl = gsap.timeline({ paused: true });

    contentRevealOpenTl
        .to(videoRevealPanel, 0.3, {
            ease: Power1.easeInOut,
            height: videoRevealContentHeight
        })
        .to(
            videoRevealContent,
            { y: 0, autoAlpha: 1, ease: Power4.easeInOut },
            "-=0.05"
        );

    //close reveal tl
    const contentRevealCloseTl = gsap.timeline({ paused: true });

    contentRevealCloseTl
        .to(videoRevealContent, 0.1, {
            y: "3rem",
            autoAlpha: 0,
            ease: Power1.easeInOut
        })
        .to(
            videoRevealPanel,
            0.3,
            { ease: Power4.easeInOut, height: 0 },
            "-=0.05"
        );

    //bind events
    videoReadMoreButton.addEventListener("click", (e) => {
        e.preventDefault();

        isActive = !isActive;

        if (isActive) {
            videoReadMoreButton.classList.add("is-active");
            openContentReveal(contentRevealOpenTl, contentRevealCloseTl);
            videoReadMoreButton.innerHTML = "Read Less";
        } else {
            videoReadMoreButton.classList.remove("is-active");
            closeContentReveal(contentRevealOpenTl, contentRevealCloseTl);
            videoReadMoreButton.innerHTML = "Read More";
        }
    });

}

//functions
function openContentReveal(contentRevealOpenTl, contentRevealCloseTl) {
    contentRevealCloseTl.pause();
    contentRevealOpenTl.restart();
}

function closeContentReveal(contentRevealOpenTl, contentRevealCloseTl) {
    contentRevealOpenTl.pause();
    contentRevealCloseTl.restart();
}
