
cookiePopup();

function cookiePopup() {

  var $cookiePopup = $('#cookiePopup'),
      $closeCookiePopup = $('#closeCookiePopup');

	setTimeout(function(){
		$cookiePopup.animate({'opacity': '1'}, 600);
	}, 400);
	
	$closeCookiePopup.on('click', function(e){
		e.preventDefault();
		$cookiePopup.fadeOut();
	});
}
