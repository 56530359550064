
function heroDiscoverMore() {
    const aboutIntroCta = document.getElementById("sectionIntroCta");
    // console.log(sectionIntroTop);

    heroDiscoverMoreButton.addEventListener("click", (e) => {
        e.preventDefault();
        gsap.to(window, { duration: 0.6, scrollTo: {y : aboutIntroCta}});
    });
}
