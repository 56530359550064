function imageCarouselInit() {

    carousels.forEach((carousel) => {

        //set up new flickity for each carousel block
        var flkty = new Flickity(carousel, {
            // options
            cellAlign: "center",
            contain: true,
            accessibility: true,
            prevNextButtons: false,
            imagesLoaded: true,
            lazyLoad: 3,
            pageDots: true,
            setGallerySize: true,
            selectedAttraction: 0.2,
            friction: 0.9,
            resize: true,
            wrapAround: true,
            on: {
                settle: function (index) {
                    // console.log('settle');

                    //cache slides
                    const slides = carousel.querySelectorAll(".js-carousel-slide");

                    //get active slide
                    let activeSlide = slides[index];

                    //find selectors
                    const activeFigCaption = activeSlide.querySelector(".js-figcaption");

                    if (activeFigCaption) {
                        //add active class to slide figcaption
                        activeFigCaption.classList.add("is-active");
                    }
                },
                change: function () {
                    // console.log('change');

                    //cache slides 
                    const slides = carousel.querySelectorAll(".js-carousel-slide");

                    slides.forEach((slide) => {
                        //find selector
                        const changedFigCaption = slide.querySelector(".js-figcaption");

                        if (changedFigCaption) {
                            //remove active class to slide figcaption
                            changedFigCaption.classList.remove("is-active");
                        }
                    });
                }
            }
        });
    });
}
