function videoFilter() {
    const videoFilterButton = document.querySelector('.js-video-filter-button'),
        videoFilter = document.querySelector('.js-video-filter');

    let filterIsOpen = false;

    videoFilterButton.addEventListener("click", (e) => {
        e.preventDefault();

        filterIsOpen = !filterIsOpen;

        if(filterIsOpen) {
            videoFilterButton.classList.add('is-active');
            videoFilter.classList.add('is-active');
        } else {
            videoFilterButton.classList.remove('is-active');
            videoFilter.classList.remove('is-active');
        }

    })

}