$(window).resize(function () {
    if ($(window).width() != width) {
        //console.log('width has changed');
        if ($(window).width() < 900) {
            let headerTriggerPoint = header.clientHeight;
        }
    }

    if ($(window).height() != height) {
        //console.log('width has changed');
  
    }

    if ($(window).width() != width || $(window).height() != height) {
        //console.log('both width & height have changed');
        if(typeof(hero) != 'undefined' && hero != null){
            heroSize();
        }
        menuSize();
}
});
